import { object } from 'yup'

import { ReactComponent as BadIcon } from 'icons/face-frown.svg'
import { ReactComponent as HappyIcon } from 'icons/face-happy.svg'
import { ReactComponent as NeutralIcon } from 'icons/face-neutral.svg'
import classNames from 'classnames'
import { Product } from 'modules/surveys'
import { trimmedString, User } from 'shared/hooks'

export const schema = object().shape({
  product: trimmedString().required('Please select a product.'),
  rate: trimmedString().required('Please rate your experience.'),
  comments: trimmedString()
    .min(5, 'Please leave a comment to help us understand your evaluation.')
    .required('Please leave a comment to help us understand your evaluation.'),
})

export const getProducts = (user: User) => {
  if (user.isSeeker)
    return [
      { id: Product.MyProfile, label: 'My profile' },
      { id: Product.EnglishVerification, label: 'English verification' },
      { id: Product.Benefits, label: 'Benefits' },
      { id: Product.LiveInterview, label: 'Live interview' },
      { id: Product.JobOffers, label: 'Job offers' },
      { id: Product.TechnicalAssessment, label: 'Technical assessment' },
      { id: Product.ReferTalent, label: 'Refer Talent (Talent Dashboard)' },
      { id: Product.Other, label: 'Other' },
    ]

  if (user.isRecruiter)
    return [
      { id: Product.ReferTalent, label: 'Refer Talent (Talent Dashboard)' },
      { id: Product.ReferHandbook, label: 'Handbook' },
      { id: Product.Benefits, label: 'Benefits' },
      { id: Product.JobBoard, label: 'Job board' },
      { id: Product.LiveChat, label: 'Live chat' },
      { id: Product.Other, label: 'Other' },
    ]

  return [
    { id: Product.JobListing, label: 'Job listing' },
    { id: Product.InterviewRequesting, label: 'Interview requesting' },
    { id: Product.JobOffers, label: 'Job offers' },
    { id: Product.TechnicalAssessment, label: 'Technical assessment' },
    { id: Product.Settings, label: 'Settings' },
    { id: Product.Other, label: 'Other' },
  ]
}

export const MAX_COMMENT_LENGTH = 600

export const RATE_OPTIONS = [
  {
    value: 'bad',
    label: 'Bad',
    icon: (className: string) => <BadIcon className={classNames('w-6 h-6', className)} />,
  },
  {
    value: 'neutral',
    label: 'Neutral',
    icon: (className: string) => <NeutralIcon className={classNames('w-6 h-6', className)} />,
  },
  {
    value: 'good',
    label: 'Good',
    icon: (className: string) => <HappyIcon className={classNames('w-6 h-6', className)} />,
  },
]
