import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useAuthenticatedUser, useNetwork } from 'shared/hooks'
import { Recruiter } from 'modules/recruiter/recruiter'

import { nullRecruiter } from './null-recruiter'

export const useRecruiter = () => {
  const {
    user: { isSeeker, isRecruiter },
  } = useAuthenticatedUser()
  const { get } = useNetwork()

  const {
    data: recruiter = nullRecruiter,
    refetch: fetchRecruiter,
    isLoading,
    isRefetching,
    isFetched,
  } = useQuery(
    'recruiter',
    async () =>
      await get<Recruiter>('recruiter').then(({ data }) => plainToInstance(Recruiter, data)),
    {
      retry: (_, error: RequestError) => error.response?.status !== 404,
      enabled: isSeeker || isRecruiter,
    },
  )

  return {
    recruiter,
    fetchRecruiter,
    isLoading: isLoading || isRefetching,
    isLoaded: isFetched,
  }
}
