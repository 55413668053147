import { Type } from 'class-transformer'
import { CompanySize } from 'company/enums'

export class Company {
  name: string
  website: string
  marketType: Nullable<string>
  companySize: Nullable<CompanySize>
  linkedInUrl: string
  publicDescription: string
}

export class JobDetails {
  @Type(() => Company)
  company: Company

  externalId: string
  title: string
  experienceRangeLabel: Nullable<string>
  contractDetailsLabel: string

  projectDescription: string
  detailedRequirements: string
  extraQualifications: string
  interviewProcess: Nullable<string>
  candidatesAdditionalInformation: Nullable<string>
  applicationFormsUrl: string
  publicUrl: Nullable<string>
}
