import { Suspense, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import { Link, Spinner, Text } from 'shared/components/atoms'
import { NavBar } from './NavBar'

import { LayoutContext } from 'contexts/layout'

import { useAuthenticatedUser } from 'shared/hooks'
import { PromotionBanner } from 'shared/components/molecules'

export const SignedInLayout = () => {
  const { user } = useAuthenticatedUser()
  const {
    shouldRootHavePadding,
    shouldRootHaveLimitedSize,
    backgroundColor,
    shouldHideNavbar,
    hideBanner,
    shouldScroll,
    setHideBanner,
  } = useContext(LayoutContext)

  useEffect(() => {
    if (shouldHideNavbar) setHideBanner(true)
  }, [shouldHideNavbar])

  const helloBarVisible =
    new Date() <= new Date('2025-03-05T19:00:00-00:00') &&
    user.isEmployer &&
    !hideBanner &&
    !shouldHideNavbar

  const fallbackSpinner = (
    <div className="h-screen w-screen bg-neutral-day">
      <Spinner />
    </div>
  )

  const helloBarText = (
    <Text size="text-base" weight="font-normal" className="w-full text-center">
      <span className="font-medium">Designing a great coding test</span> - On March 5th, join
      Strider's CEO, Neal Kemp, for a webinar on how to build and optimize coding tests for
      engineers.{' '}
      <Link url="https://us06web.zoom.us/webinar/register/3717387813142/WN_QUo1Mm43Re2Aj6FvtyUjzA">
        Register now!
      </Link>
    </Text>
  )

  return (
    <div className={classNames('flex flex-col items-center min-h-screen', backgroundColor)}>
      <NavBar />
      <PromotionBanner
        id="vet-and-hire-talent-b2b-2024-11-12"
        text={helloBarText}
        isVisible={helloBarVisible}
      >
        <main
          className={classNames(
            'flex-grow flex flex-col',
            'w-full  h-full',
            'px-6 py-6 md:pb-12 lg:pb-20 lg:px-0',
            {
              '!p-0': !shouldRootHavePadding,
              'max-w-[1032px]': shouldRootHaveLimitedSize,
              'mt-[60px] md:mt-16': user.isOnboarded && !shouldHideNavbar,
              'mt-0': !user.isOnboarded || shouldHideNavbar,
              // Weird breakpoint needed because this is when the banner message breaks lines
              'mb-16 md:mb-0 md:!mt-24 [@media(min-width:895px)]:!mt-20':
                !hideBanner && user.isEmployer,
              'md:max-h-[calc(100vh-136px)] [@media(min-width:895px)]:max-h-[calc(100vh-120px)] overflow-hidden':
                !shouldScroll && !hideBanner,
              'md:max-h-[calc(100vh-64px)] overflow-hidden': !shouldScroll && hideBanner,
            },
          )}
        >
          <Suspense fallback={fallbackSpinner}>
            <Outlet />
          </Suspense>
        </main>
      </PromotionBanner>
    </div>
  )
}
