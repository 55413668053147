import { UpdateProfileAboutSchema } from 'profile/domain'
import { isValidPhoneNumber, locationShape } from 'shared/components/atoms'
import { websiteSchema, trimmedString } from 'shared/hooks'
import { AnySchema, object } from 'yup'

export { About } from './About'

export const buildSchema = (isProductDesigner: boolean) =>
  object().shape<Record<keyof UpdateProfileAboutSchema, AnySchema>>({
    firstName: trimmedString().required(),
    lastName: trimmedString().required(),
    location: locationShape,
    personalSite: websiteSchema
      .when([], {
        is: () => isProductDesigner,
        then: trimmedString().required(),
        otherwise: trimmedString().nullable().min(0),
      })
      .test('is_not_linkedin', 'The site should not be your LinkedIn profile.', function (value) {
        if (value) {
          return !/linkedin.com\/in\//gi.test(value)
        }

        return true
      }),
    gitRepositoriesUrl: websiteSchema
      .nullable(true)
      .min(0)
      .test('is_gitUrl', 'Please enter a valid GitHub/GitLab URL.', function (value) {
        if (value) {
          return /github.com\//gi.test(value) || /gitlab.com\//gi.test(value)
        }

        return true
      }),
    whatsAppNumber: trimmedString()
      .optional()
      .nullable()
      .test('valid_phone_number', 'Please enter a valid phone number.', async (value) => {
        if (value) return isValidPhoneNumber(value)

        return true
      }),
  })
