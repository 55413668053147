import { AnySchema, object } from 'yup'
import { FormValues } from '.'
import { trimmedString } from 'shared/hooks'

export const buildSchema = (uniqueValidation: (email: string | undefined) => boolean) =>
  object().shape({
    email: trimmedString()
      .required('Email required')
      .email('Insert a valid email')
      .test('unique', 'Email already taken', uniqueValidation),
  } as Record<keyof FormValues, AnySchema>)
