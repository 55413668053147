import { trimmedString } from 'shared/hooks'
import { array, object } from 'yup'

export const schema = object().shape({
  proficiencies: array().of(
    object({
      id: trimmedString().required(),
      label: trimmedString().required(),
    }),
  ),
})
