/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormReturn, UseFormSetFocus } from 'react-hook-form'
// eslint-disable-next-line no-restricted-imports
import { string } from 'yup'

export { useForm } from './useForm'

export type FormFieldValueSetter = (
  name: string,
  value: any,
  options?: { shouldValidate?: boolean; shouldDirty?: boolean; shouldTouch?: boolean },
) => void

export interface FormControl
  extends Omit<
    UseFormReturn,
    'register' | 'getError' | 'getFieldState' | 'formState' | 'resetField' | 'unregister'
  > {
  register: (key: string) => any[]
  getError: any
  isDirty: boolean
  isValid: boolean
  dirtyFields: { [x: string]: any }
  errors: { [x: string]: any }
  touchedFields: { [x: string]: any }
  submitCount: number
  setFocus: UseFormSetFocus<Record<string, any>>
}

export const trimmedString = () => string().trim()

export const validWebsite = (website: string) =>
  /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?)$/.test(
    website.toLowerCase(),
  )

export const websiteSchema = trimmedString()
  .nullable()
  .min(1, 'Required field.')
  .max(1000, 'Website cannot be longer than 1000 characters.')
  .test('valid_website', 'Please insert a valid website', function (value) {
    if (!value) return true
    return validWebsite(value)
  })

const validLinkedInProfileUrl = (url: string) => {
  const lowerCaseContent = decodeURIComponent(String(url).trim().toLocaleLowerCase())
  const linkedInProfileRegExp = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[A-zÀ-ü0-9_-]+\/?$/

  return linkedInProfileRegExp.test(lowerCaseContent)
}

const validLinkedInCompanyUrl = (url: string) => {
  const lowerCaseContent = decodeURIComponent(String(url).trim().toLocaleLowerCase())
  const linkedInCompanyRegExp =
    /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/company\/[A-zÀ-ü0-9_-]+\/?$/

  return linkedInCompanyRegExp.test(lowerCaseContent)
}

export const linkedInProfileUrlSchema = trimmedString()
  .max(150, 'URL is too big.')
  .typeError('Please enter a valid URL.')
  .test('is_linkedInUrl', 'Please enter a valid LinkedIn profile URL.', function (value) {
    if (value) {
      return validLinkedInProfileUrl(value)
    }

    return true
  })
  .required('Please enter a valid URL.')

export const linkedInCompanyUrlSchema = trimmedString()
  .max(150, 'URL is too big.')
  .typeError('Please enter a valid URL.')
  .test('is_linkedInCompanyUrl', 'Please enter a valid LinkedIn company URL.', function (value) {
    if (!value) return true
    return validLinkedInCompanyUrl(value)
  })
  .required('Please enter a valid URL.')
