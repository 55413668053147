import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { JobDetails } from 'job-listing/domains'

import { useNetwork, useToast } from 'shared/hooks'

export const useJobDetails = (externalId: string) => {
  const navigate = useNavigate()
  const { get } = useNetwork()
  const { toastError } = useToast()

  const { data: jobDetails, isLoading } = useQuery(
    ['job-details', externalId],
    () =>
      get<JobDetails>(`/job-opportunities/${externalId}/details`).then(({ data }) =>
        plainToInstance(JobDetails, data),
      ),
    {
      onError: (error: RequestError) => {
        if ([403, 404].includes(error.response?.status)) return

        toastError(
          `Error fetching job details: ${error.response?.data?.message ?? 'Unknown error'}`,
        )
        navigate('/')
      },
      enabled: Boolean(externalId),
      retry: (failureCount, error) =>
        failureCount < 3 && ![403, 404].includes(error.response?.status),
    },
  )

  return { jobDetails, isLoading }
}
