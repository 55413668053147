import { trimmedString } from 'shared/hooks'
import { array, bool, number, object } from 'yup'

export const schema = object({
  status: object().shape({
    pending: bool(),
    signedUp: bool(),
    profileCompleted: bool(),
    hired: bool(),
    hiredOnProbation: bool(),
    rejected: bool(),
    leftOnProbation: bool(),
  }),
  roles: array().of(
    object().shape({
      id: number(),
      label: trimmedString(),
    }),
  ),
  search: trimmedString(),
})

export const defaultValues = {
  status: {
    pending: false,
    signedUp: false,
    profileCompleted: false,
    hired: false,
    hiredOnProbation: false,
    rejected: false,
    leftOnProbation: false,
  },
  roles: [],
}
