import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface TagProps {
  id: string | number | null
  label: string
  persisted?: boolean
  highlight?: boolean
  index?: number
  className?: string
  tagClassName?: string
  isReadOnly?: boolean
  icon?: React.ReactNode
  hint?: string | number
  background?: 'bg-success-lighter' | 'bg-neutral-lightest' | 'bg-neutral-lighter'
  onRemove?(id: number | string | null, label: string | null): void
}

export const Tag = ({
  id,
  label,
  persisted,
  highlight,
  index,
  onRemove,
  className,
  tagClassName,
  isReadOnly,
  icon,
  hint,
  background = 'bg-neutral-lightest',
}: TagProps) => {
  const handleRemove = (id: number | string | null, label: string, persisted?: boolean) => {
    if (!onRemove) return
    if (id) return onRemove(id, null)
    if (persisted === false) onRemove(null, label)
  }

  const applyHighlight = (highlight?: boolean) => highlight === undefined || highlight

  return (
    <div
      className={classNames(
        'flex justify-between items-center rounded-[34px] py-1 px-3 gap-1',
        applyHighlight(highlight) ? background : 'bg-neutral-lightest',
        tagClassName,
      )}
      key={index}
      role="listitem"
      aria-label={label}
    >
      {icon && applyHighlight(highlight) && <div className="-ml-1">{icon}</div>}
      <Text size="text-sm" weight="font-normal" className={className}>
        {label}{' '}
        {hint !== undefined && <span className="text-inherit text-neutral-dark">&nbsp;{hint}</span>}
      </Text>
      {!isReadOnly && (
        <div
          className="inline-flex cursor-pointer"
          onClick={() => handleRemove(id, label, persisted)}
        >
          <CloseIcon aria-label="Remove" className="h-4 w-4 stroke-neutral-medium" />
        </div>
      )}
    </div>
  )
}
