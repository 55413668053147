import { trimmedString } from 'shared/hooks'
import { array, object } from 'yup'

export const schema = object().shape({
  assessments: array().of(
    object({
      id: trimmedString().required(),
      name: trimmedString().required(),
      type: trimmedString().required(),
    }),
  ),
})
