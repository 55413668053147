import { Roles } from 'account/enums'
import { plainToInstance } from 'class-transformer'
import { Company } from 'company/domain'
import { CompanySize } from 'company/enums'
import { HiringMode } from 'shared/hooks'

export type FetchCompanyInfoResponse = {
  company: {
    id: number
    name: string
    website: Nullable<string>
    linkedInUrl: string
    employeesNumber: Nullable<number>
    candidatesTrackerUrl: Nullable<string>
    approverEmail: Nullable<string>
    approvedAt: Nullable<Date>
    hiringMode: HiringMode
    updatedAt: Date
    marketType: Nullable<{
      id: number
      label: string
    }>
    companySize: Nullable<CompanySize>
  }
  employees: Array<{ id: number; role: Roles.EmployerOwner | Roles.EmployerViewer; email: string }>
  invites: Array<{ id: number; role: Roles.EmployerOwner | Roles.EmployerViewer; email: string }>
}

export class FetchCompanyInfoDTO {
  constructor(private readonly response: FetchCompanyInfoResponse) {}

  toDomain(): Company {
    const object: Plain<Company> = {
      id: this.response.company.id,
      name: this.response.company.name,
      website: this.response.company.website,
      linkedInUrl: this.response.company.linkedInUrl,
      employees: this.response.employees,
      approved: Boolean(this.response.company.approvedAt),
      invites: this.response.invites,
      marketType: this.response.company.marketType,
      companySize: this.response.company.companySize,
      hiringMode: this.response.company.hiringMode,
    }

    return plainToInstance(Company, object)
  }
}
