import { cloneElement, forwardRef, KeyboardEvent, useRef, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import Downshift, { Callback, StateChangeFunction, StateChangeOptions } from 'downshift'

import { Input } from 'shared/components/atoms'
import { Placeholder, Row } from 'shared/components/organisms'
import { cn } from 'modules/shared'

import { SuggestionList } from './components/SuggestionList'
import { Suggestion } from './types'

interface AutocompleteProps {
  suggestions: Suggestion[]
  selectedSuggestions?: Suggestion[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  setValue: UseFormSetValue<Record<string, string>>
  name: string
  disabled?: boolean
  clearInputValue?: boolean
  allowCreation?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAdd: (value: Suggestion, name: string) => Promise<any>
  inline?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  maxLength?: number
  warningOnly?: boolean
  enableErrorState?: boolean
  inputIcon?: React.ReactElement
  matchDescription?: boolean
  showDescription?: boolean
  className?: string
  suggestionsClassName?: string
  autoFocus?: boolean
  onBlur?: () => void
  onInputChange?: (value: string) => string
  id?: string
  threshold?: number
  distance?: number
  isLoading?: boolean
}

export const Autocomplete = forwardRef(function Autocomplete(
  {
    id,
    suggestions,
    selectedSuggestions = [],
    register,
    setValue,
    name,
    disabled,
    onKeyDown,
    handleAdd,
    clearInputValue = true,
    allowCreation = true,
    inline = false,
    placeholder,
    maxLength,
    warningOnly,
    enableErrorState = false,
    inputIcon,
    matchDescription,
    showDescription,
    className,
    suggestionsClassName,
    onBlur,
    onInputChange,
    autoFocus = false,
    threshold,
    distance,
    isLoading,
  }: AutocompleteProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) {
  const buildStyledIcon = () => {
    if (!inputIcon) return
    return cloneElement(inputIcon, {
      style: {
        stroke: 'inherit',
        height: '16px',
        width: '16px',
        position: 'absolute',
        margin: '12px 12px 12px 16px',
      },
    })
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const [lastSelectedItemLabel, setLastSelectedItemLabel] = useState<Nullable<string>>(null)
  const [error] = register(name)

  const handleChange = (selectedItem: Nullable<Suggestion>) => {
    if (!selectedItem) return

    setValue(name, clearInputValue ? '' : selectedItem.label)
    handleAdd(selectedItem, name)
    setLastSelectedItemLabel(selectedItem.label)
  }

  const handleBlur = () => {
    if (!lastSelectedItemLabel) return setValue(name, '')
    setValue(name, clearInputValue ? '' : lastSelectedItemLabel)
  }

  const handleStateChange = (state: StateChangeOptions<Suggestion>) => {
    const callbacks: Partial<Record<typeof state.type, () => void>> = {
      [Downshift.stateChangeTypes.unknown]: () =>
        setLastSelectedItemLabel(inputRef.current?.value || null),
      [Downshift.stateChangeTypes.blurInput]: handleBlur,
      [Downshift.stateChangeTypes.keyDownEscape]: handleBlur,
      [Downshift.stateChangeTypes.mouseUp]: handleBlur,
    }
    const eventCallback = callbacks[state.type]
    eventCallback && eventCallback()
  }

  const handleFocus = (
    openMenu: (cb?: Callback) => void,
    setState: (
      stateToSet: Partial<StateChangeOptions<Suggestion>> | StateChangeFunction<Suggestion>,
      cb?: Callback,
    ) => void,
  ) => {
    openMenu()
    if (clearInputValue) setState({ inputValue: '' })
  }

  if (isLoading) {
    return (
      <Placeholder className="-mb-2">
        <Row height="h-10" width="w-12/12" />
      </Placeholder>
    )
  }

  return (
    <Downshift
      ref={ref}
      onChange={handleChange}
      onInputValueChange={onInputChange}
      onOuterClick={handleBlur}
      onStateChange={handleStateChange}
      defaultHighlightedIndex={0}
      itemToString={(suggestion: Nullable<Suggestion>) => suggestion?.label || ''}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        openMenu,
        setState,
      }) => (
        <div className={cn('relative flex', { 'inline-flex': inline })}>
          {buildStyledIcon()}
          <Input
            {...getInputProps({
              onKeyDown,
              onFocus: openMenu,
              onClick: () => handleFocus(openMenu, setState),
              onBlur: onBlur,
              value: inputValue,
            })}
            id={id}
            ref={inputRef}
            name={name}
            register={register}
            disabled={disabled}
            showFieldError={false}
            injected={inline}
            placeholder={placeholder}
            maxLength={maxLength}
            autoFocus={autoFocus}
            className={cn(
              {
                'disabled:bg-transparent': inline,
                'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]':
                  Boolean(error),
                'pl-11': Boolean(inputIcon),
              },
              className,
            )}
            warningOnly={warningOnly}
            enableErrorState={enableErrorState}
          />
          <SuggestionList
            suggestions={suggestions}
            selectedSuggestions={selectedSuggestions}
            inputValue={inputValue}
            isOpen={isOpen}
            selectedItem={selectedItem}
            highlightedIndex={highlightedIndex}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            allowCreation={allowCreation}
            matchDescription={matchDescription}
            showDescription={showDescription}
            className={suggestionsClassName}
            threshold={threshold}
            distance={distance}
          />
        </div>
      )}
    </Downshift>
  )
})
