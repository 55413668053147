import { RecordingStep, Steps } from './enums'

interface StepData {
  title: string
  subtitle?: Optional<string>
  dos: Array<string>
  dosDescription?: string
  boost: Array<string>
  donts: Array<string>
  minLengthInSeconds: number
  maxLengthInSeconds: number
}

export const STEPS = [
  Steps.HardwareCheckup,
  Steps.TechnicalChallenges,
  Steps.FutureInterviews,
  Steps.Submit,
]

export const STEP_DATA: Record<RecordingStep, StepData> = {
  [Steps.TechnicalChallenges]: {
    title: 'Tell us about your past technical challenges',
    subtitle: 'Follow the instructions to record a video as part of you English evaluation.',
    dos: [
      'Describe the situation and its context',
      'What were your tasks and responsibilities?',
      'What steps did you take to address them?',
      'What was the outcome?',
      'What lessons did you learn from it?',
    ],
    dosDescription: 'Tell us the most significant technical challenge you have ever faced',
    boost: [
      'Be specific and showcase your expertise',
      'Tell the whole story to show how good you were at resolving the problem',
      'Share results achieved and the business impact of your solution ',
    ],
    donts: [
      "Avoid reciting the script. It'll sound artificial",
      'Do not speak any language other than English',
      'Do not record this video vertically (like an Instagram story), in a room with poor lighting, or a noisy place',
    ],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MAX_LENGTH ?? '180',
    ),
  },
  [Steps.FutureInterviews]: {
    title: 'Show us your English skills for future interviews',
    dos: [
      'Ask three open-ended questions that you would ask in a live interview with potential employers.',
      'Explain the reasoning behind each question.',
    ],
    boost: [
      'Focus on questions related to company culture, product, industry trends, or technical/business challenges, as these tend to generate higher engagement.',
      'Present your questions one at a time, structuring your thoughts clearly and concisely.',
    ],
    donts: [
      "Avoid asking questions about salary, benefits, time off, company size, equity, etc. You'll have the opportunity to discuss these once you receive your first interview request.",
    ],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MAX_LENGTH ?? '120',
    ),
  },
}
