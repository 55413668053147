import { FieldError } from 'shared/components/atoms'
import { ReactNode, SelectHTMLAttributes } from 'react'

import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg'

import styles from './Select.module.scss'
import { cn } from 'modules/shared'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  options: Array<{ id: number | string; label: string }>
  placeholder?: string
  id?: string
  className?: string
  showFieldError?: boolean
  children?: ReactNode
  hideBorder?: boolean
  floatingError?: boolean
}

export const Select = ({
  register,
  name,
  options,
  placeholder,
  id,
  className,
  showFieldError = true,
  children,
  hideBorder,
  floatingError = false,
  ...restProps
}: SelectProps) => {
  const [error, fieldRegister] = register(name)

  return (
    <div className={cn('relative', className)}>
      <select
        id={id}
        name={name}
        defaultValue={-1}
        className={cn(
          'w-full h-10 px-4 py-2 mr-7',
          'border border-neutral-light rounded-lg bg-white',
          'font-inter font-normal text-base text-neutral-darkest',
          'relative',
          {
            'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]':
              error && showFieldError,
            'border-transparent': hideBorder,
          },
          [styles.custom_select],
        )}
        {...fieldRegister()}
        {...restProps}
      >
        {placeholder && (
          <option value={-1} disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </select>
      {children}
      {showFieldError && <FieldError message={error} floatingError={floatingError} />}
      <ChevronDownIcon
        className={cn('w-6 h-6 stroke-neutral-darkest absolute right-4 top-2 pointer-events-none')}
      />
    </div>
  )
}
