import { KeyboardEvent, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import { UseFormSetValue } from 'react-hook-form'
import { object } from 'yup'
import classNames from 'classnames'

import { Autocomplete, FieldError, FieldHint, Suggestion, Label } from 'shared/components/atoms'
import { Text } from 'components/Text'
import { Tags as TagsComponent } from './Tags'

import { useForm, trimmedString } from 'shared/hooks'

interface TagsProps {
  isVisible: boolean
  tags: Array<{ id: number; label: string }>
  options: Array<Suggestion>
  name: string
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getError: (field: string) => any
  allowCreation?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createOption?: (label: string) => Promise<AxiosResponse<Suggestion, any>>
  onAdd?: (value: Suggestion) => void
  onRemove?: (id: number) => void
  maxTagsNumber?: number
  maxLength?: number
  matchDescription?: boolean
  showDescription?: boolean
  hint?: string
}

const schema = object().shape({
  value: trimmedString(),
})

export const TagsArea = ({
  isVisible,
  tags,
  options,
  name,
  label,
  setValue,
  getError,
  createOption,
  allowCreation = false,
  onAdd,
  onRemove,
  maxTagsNumber,
  maxLength = 100,
  matchDescription,
  showDescription,
  hint,
}: TagsProps) => {
  const inputRef = useRef<{ inputId: string }>()
  const [inputText, setInputText] = useState<null | string>(null)
  const { register, setValue: setOptionValue } = useForm({ schema, defaultValues: {} })

  const addTag = (value: Suggestion) => {
    const newTags = tags.concat([{ id: Number(value.id!), label: value.label }])
    setValue(name, newTags, {
      shouldValidate: true,
    })

    if (onAdd) onAdd(value)
  }

  const handleAdd = async (value: Suggestion) => {
    setInputText(null)

    if (value.id) {
      return addTag(value)
    }

    createOption!(value.label).then(({ data }) => addTag(data))
  }

  const handleRemove = (id: number) => {
    const newValues = tags.filter(({ id: valueId }) => valueId !== id)

    setValue(name, newValues, {
      shouldValidate: true,
    })

    if (onRemove) onRemove(id)
  }

  const removeLastItem = () => {
    const lastItem = tags[tags.length - 1]

    if (!lastItem) return

    handleRemove(lastItem.id)
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement

    if (event.key === 'Backspace' && !inputText && !input.value) {
      removeLastItem()
    }

    setInputText(input.value)
  }

  const handleClick = () => {
    const input = document.getElementById(inputRef.current?.inputId as string)
    input?.focus()
  }

  if (!isVisible) return null
  return (
    <>
      {Boolean(label) && (
        <Label htmlFor="value" size="text-sm">
          {label}
        </Label>
      )}

      {Boolean(hint) && <FieldHint className="mb-6">{hint}</FieldHint>}
      <div
        className={classNames(
          'border-[1.5px] min-h-[116px] lg:min-h-[160px] p-4',
          'border-neutral-light rounded-lg overflow-visible',
          {
            'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]':
              getError(name),
          },
        )}
        onClick={handleClick}
      >
        <TagsComponent
          values={tags}
          isReadOnly={false}
          onRemove={handleRemove}
          omitWhenEmpty={false}
        >
          <Autocomplete
            inline
            ref={inputRef}
            register={register}
            setValue={setOptionValue}
            name="value"
            suggestions={options}
            handleAdd={handleAdd}
            allowCreation={allowCreation}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            disabled={Boolean(maxTagsNumber && tags.length >= maxTagsNumber)}
            matchDescription={matchDescription}
            showDescription={showDescription}
            className={getError(name) ? '!bg-warning-lighter' : ''}
          />
        </TagsComponent>
      </div>
      {maxTagsNumber && (
        <Text className="mt-2 text-neutral-dark">
          {tags.length}/{maxTagsNumber}
        </Text>
      )}
      <FieldError message={getError(name)} />
    </>
  )
}
