import { ExperienceRange } from 'modules/candidates/experience-range/experience-range'

export const formatCurrency = (
  value: string | number | undefined,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
) => {
  const currency = Number(value)
  return currency.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  })
}

export const formatAbbreviatedCurrency = (number: string | number) => {
  const suffixes = ['', 'k', 'M', 'B', 'T']

  let roundedNumber = Number(number)
  let suffixIndex = 0

  while (roundedNumber >= 1000 && suffixIndex < suffixes.length - 1) {
    roundedNumber /= 1000
    suffixIndex++
  }

  const formattedNumber = roundedNumber.toLocaleString(undefined, { maximumFractionDigits: 0 })

  return `$${formattedNumber}${suffixes[suffixIndex]}`
}

const formatPercentage = (value: number) =>
  value.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  }) + '%'

const formatDate = ({
  date,
  day = 'numeric',
  month = 'short',
  year = 'numeric',
  timeZone,
}: {
  date: Date
  day?: 'numeric' | '2-digit' | null
  month?: 'numeric' | 'short' | 'long'
  year?: '2-digit' | 'numeric'
  timeZone?: 'UTC'
}) => {
  if (date.getMilliseconds() === 0 && !timeZone) {
    /*
    If you getting this error, you probably have a date only value that was converted 
    to local user timezone. That may end up rendering a wrong date (1 day variation) depending on the timzone 
    difference and the time of the day. Please use the TimeZone: 'UTC' to avoid such issue. 
    */
    // eslint-disable-next-line no-console
    console.error(
      'Date format attempt with (apparent) UTC value without timeZone param: ' + date.toISOString(),
    )
  }

  return date.toLocaleDateString('en-US', {
    day: day || undefined,
    timeZone,
    month,
    year,
  })
}

export const formatDateTime = ({
  date,
  day = 'numeric',
  month = 'short',
  year = 'numeric',
  isInput = false,
}: {
  date: Date
  day?: undefined | 'numeric' | '2-digit'
  month?: 'numeric' | 'short' | 'long'
  year?: '2-digit' | 'numeric'
  isInput?: boolean
}) => {
  const formattedDate = formatDate({ date, day, month, year })
  const formattedTime = date.toLocaleTimeString('en-US', { timeStyle: 'short' })

  return `${formattedDate}${isInput ? ' ' : ' at '}${formattedTime}`
}

const getMonthFromDate = (date: Date) =>
  date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })

const getDayFromDate = (date: Date) => date.getUTCDate().toString().padStart(2, '0')

const round = (value: number) => Math.round((Number(value) + Number.EPSILON) * 100) / 100

const getWrittenTimeDifference = (date: string) => {
  const now = new Date().getTime()
  const originalDate = new Date(date).getTime()
  const oneHour = 1000 * 60 * 60
  const differenceInHours = (now - originalDate) / oneHour
  const differenceInMinutes = differenceInHours * 60
  const differenceInDays = Math.floor(differenceInHours / 24)

  if (differenceInDays >= 30) return `${Math.floor(differenceInDays / 30)} months ago`
  if (differenceInHours >= 48) return `${Math.floor(differenceInHours / 24)} days ago`
  if (differenceInHours >= 24) return '1 day ago'
  if (differenceInHours >= 2) return `${Math.floor(differenceInHours)} hours ago`
  if (differenceInHours >= 1) return '1 hour ago'
  if (differenceInMinutes >= 2) return `${Math.floor(differenceInMinutes)} minutes ago`
  return 'a few minutes ago'
}

const getNextIntegerPrefix = (values: Array<string>) => {
  let nextPrefix = 1

  for (const value of values) {
    const prefix = parseInt(value.split('-')[0], 10)
    if (isNaN(prefix)) continue
    if (prefix >= nextPrefix) nextPrefix = prefix + 1
  }

  return nextPrefix.toString()
}

const formatNumberByLanguage = (value: number, language: string = navigator.language) =>
  value.toLocaleString(language)

const formatTwoDigitsNumber = (value: number) => String(value).padStart(2, '0')

const formatCurrencyRange = (
  minimumValue?: string | number | null,
  maximumValue?: string | number | null,
) => {
  let text = ''
  if (minimumValue) text += formatCurrency(minimumValue)
  if (minimumValue && maximumValue) text += ' - '
  if (maximumValue) text += formatCurrency(maximumValue)
  return text
}

const formatMonthsGap = (startDate: Date, endDate?: Date, showDifference = true) => {
  const getMonthDifference = (startDate: Date, endDate: Date = new Date()) => {
    const { years, months } = new ExperienceRange(startDate, endDate)

    if (years <= 0 && months <= 0) return 'less than 1 mo'
    if (years <= 0) return `${months} mo${months === 1 ? '' : 's'}`
    if (years > 0 && months === 0) return `${years} yr`

    return `${years} yr ${months} mos`
  }

  const formatMonthsGapDate = (date: Date) =>
    formatDate({
      date,
      day: null,
      timeZone: 'UTC',
      month: 'short',
      year: 'numeric',
    })

  return `${formatMonthsGapDate(startDate)} - ${
    endDate ? formatMonthsGapDate(endDate) : 'present'
  }${showDifference ? ' • ' + getMonthDifference(startDate, endDate) : ''}`
}

const formatPlural = (text: string, itemsAmount: number) => {
  return `${text}${itemsAmount <= 1 ? '' : 's'}`
}

const normalizeLinkedInUrl = (content: string) => {
  const lowerCaseContent = content.toLowerCase()

  if (!lowerCaseContent.includes('linkedin.com/')) return content

  try {
    const prefix = 'https://www.linkedin.com/'
    const suffix = lowerCaseContent.split('linkedin.com/').pop()

    const url = new URL(prefix + suffix)
    url.search = ''
    return url.toString().split('www.').pop()
  } catch {
    return content
  }
}

const formatDayOfTheWeek = (date: Date, timeZone?: 'UTC') => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const formattedDate = formatDate({ date, timeZone })
  return days[new Date(formattedDate).getDay()]
}

export const isDateTime = (value: string) =>
  value.split('T').length > 1 && !isNaN(new Date(value).getTime())

export const transformToHtmlList = (text: string) => {
  if (!text) return ''

  return `<ul>${text
    .split('\n')
    .map((item) => `<li>${item}</li>`)
    .join('')}</ul>`
}

export const useFormatting = () => {
  return {
    formatCurrency,
    formatAbbreviatedCurrency,
    formatPercentage,
    formatDate,
    getMonthFromDate,
    getDayFromDate,
    round,
    getWrittenTimeDifference,
    formatDateTime,
    getNextIntegerPrefix,
    formatNumberByLanguage,
    formatTwoDigitsNumber,
    formatCurrencyRange,
    formatMonthsGap,
    formatPlural,
    normalizeLinkedInUrl,
    formatDayOfTheWeek,
  }
}
