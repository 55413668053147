import { FieldError } from 'shared/components/atoms'
import { useEffect, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { UseFormSetValue } from 'react-hook-form'
import { number, object } from 'yup'
import { inputStyles } from '../Input'
import { LocationInfo, LocationType } from './location-info'

import './LocationSearchInput.scss'
import { trimmedString } from 'shared/hooks'

const errorMessage = 'Please enter a location.'
export const locationShape = object()
  .shape({
    label: trimmedString(),
    city: trimmedString()
      .typeError('Please select a valid city.')
      .required('Please select a valid city.'),
    state: trimmedString()
      .typeError('Please select a valid state.')
      .required('Please select a valid state.'),
    country: trimmedString()
      .typeError('Please select a valid country.')
      .required('Please select a valid country.'),
    placeId: trimmedString().required(errorMessage),
    utcOffsetMinutes: number().required(errorMessage),
  })
  .typeError(errorMessage)
  .required(errorMessage)

export const countryShape = object()
  .shape({
    country: trimmedString().required(errorMessage),
    placeId: trimmedString().required(errorMessage),
    utcOffsetMinutes: number().required(errorMessage),
  })
  .typeError(errorMessage)
  .required(errorMessage)

interface LocationSearchInputProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  error?:
    | string
    | { city?: { message: string }; state?: { message: string }; country?: { message: string } }
  location: LocationInfo
  locationType?: LocationType
  placeholder?: string
  showFieldError?: boolean
  warningOnly?: boolean
}

export const LocationSearchInput = ({
  name,
  setValue,
  error,
  location,
  locationType = LocationType.City,
  placeholder = 'e.g. Rio de Janeiro',
  showFieldError = true,
  warningOnly = false,
}: LocationSearchInputProps) => {
  const classes = inputStyles({ showError: showFieldError, error: Boolean(error) })
  const [interactions, setInteractions] = useState(0)
  const parsedLocation = new LocationInfo(location || {})

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GMAPS_API_KEY,
    inputAutocompleteValue: 'off',
    language: 'en',
    options: {
      types: [locationType],
      fields: ['place_id', 'address_components', 'formatted_address', 'utc_offset_minutes'],
    },
    onPlaceSelected: (place) => {
      const location = LocationInfo.fromAddressComponents(place)
      setValue(name, location, { shouldValidate: true, shouldTouch: true })
    },
  })

  useEffect(() => {
    // resets input value if the user starts typing something but doesn't select a new location
    if (ref.current && location) {
      ref.current.value = parsedLocation.label
    }

    if (interactions && !location) {
      setValue(name, null, { shouldValidate: true, shouldTouch: true })
    }
  }, [parsedLocation.label, interactions])

  const handleBlur = () => {
    if (!ref.current?.value) {
      return setValue(name, null, { shouldValidate: true, shouldTouch: true })
    }

    setInteractions((current) => current + 1)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    // prevents unexpected form submission
    if (event.key == 'Enter') {
      event.preventDefault()
    }
  }

  const errorMessage =
    typeof error === 'string'
      ? error
      : error?.city?.message || error?.state?.message || error?.country?.message

  return (
    <>
      <input
        className={classes}
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        ref={ref}
        onKeyDown={handleKeyDown}
        id={name}
      />
      {showFieldError && <FieldError message={warningOnly ? undefined : errorMessage} />}
    </>
  )
}
