import { array, boolean, date, object } from 'yup'
import { trimmedString } from 'shared/hooks'

export { RegularOfferForm } from './RegularOfferForm'

export const schema = object()
  .shape({
    jobTitle: trimmedString().required('Please share the job title for this offer.'),
    preferredStartDates: array()
      .of(date())
      .min(2, 'Suggest at least 2 start dates')
      .max(10, 'Suggest up to 10 start dates'),
    description: trimmedString().required('Please provide a description'),
    contractUrl: trimmedString().nullable(),
    termsAccepted: boolean().isTrue('You should accept the Supplemental Terms.'),
  })
  .required()
