import { JobListingSettings } from 'job-listing/domains'
import { trimmedString } from 'shared/hooks'
import { AnySchema, boolean, object } from 'yup'

export { JobListingSettingsModal } from './JobListingSettingsModal'

export const schema = object<Record<keyof JobListingSettings, AnySchema>>().shape({
  introCallRequired: boolean(),
  finalInterviewRequired: boolean(),
  introCallScheduleLink: trimmedString()
    .url('The link should be a valid URL.')
    .max(300, 'The link should be no longer than 300 characters.')
    .when('introCallRequired', {
      is: true,
      then: (schema) => schema.required('This field is required.'),
    }),
  finalInterviewScheduleLink: trimmedString()
    .url('The link should be a valid URL.')
    .max(300, 'The link should be no longer than 300 characters.')
    .when('finalInterviewRequired', {
      is: true,
      then: (schema) => schema.required('This field is required.'),
    }),
})
