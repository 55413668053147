export enum CompanySize {
  Micro = 'micro',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  VeryLarge = 'very_large',
  Huge = 'huge',
}

export type CompanySizeDesirableOptions = 'ideal' | 'yes' | 'no'

export const companySizeLabels = {
  [CompanySize.Micro]: 'Micro',
  [CompanySize.Small]: 'Small',
  [CompanySize.Medium]: 'Medium',
  [CompanySize.Large]: 'Large',
  [CompanySize.VeryLarge]: 'Very large',
  [CompanySize.Huge]: 'Huge',
}

export const companySizeExtendedLabels = {
  [CompanySize.Micro]: 'Micro (1-10 employees)',
  [CompanySize.Small]: 'Small (11-50 employees)',
  [CompanySize.Medium]: 'Medium (51-250 employees)',
  [CompanySize.Large]: 'Large (251-1,000 employees)',
  [CompanySize.VeryLarge]: 'Very large (1,001-5,000 employees)',
  [CompanySize.Huge]: 'Huge (5,001+ employees)',
}
