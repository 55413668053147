import { PositionsSlug } from 'modules/shared/enums'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { emptySkill } from 'profile/consts'
import { UpdateProfileRolesAndSkillsSchema } from 'profile/domain/update-profile'
import { Skill } from 'profile/types'
import { trimmedString } from 'shared/hooks'
import { AnySchema, array, object } from 'yup'

export { RolesAndSkills } from './RolesAndSkills'

export const schema = object().shape<Record<keyof UpdateProfileRolesAndSkillsSchema, AnySchema>>({
  stacks: array()
    .of(
      object().shape({
        stackId: trimmedString(),
        stackLabel: trimmedString(),
        yearsOfExperience: trimmedString(),
      }),
    )
    .test('at_least_three_skills', 'Choose at least three skills.', function () {
      return this.parent.stacks.filter((skill: Skill) => Boolean(Number(skill.stackId))).length >= 3
    }),
  openToRoles: array()
    .of(
      object().shape({
        id: trimmedString().nullable(),
        label: trimmedString(),
      }),
    )
    .min(1, 'Choose at least one role.'),
})

export const buildDefaultValues = (profile: ProfileOverview): UpdateProfileRolesAndSkillsSchema => {
  const initialStacks = profile.profileStacks.map((stack) => ({
    stackId: stack.stack.id,
    stackLabel: stack.stack.label,
    yearsOfExperience: stack.experience,
  }))

  const initialOpenToRoles = profile.interestRoles.concat(
    profile.otherInterestRoles?.map((role, index) => ({
      id: (index + 1) * -1,
      label: role,
      roleType: PositionsSlug.Other,
    })) || [],
  )

  return {
    stacks: initialStacks.length ? initialStacks : Array(3).fill(emptySkill),
    openToRoles: initialOpenToRoles,
  }
}
