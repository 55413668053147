import { CompanySize } from 'company/enums'
import { PreferencesFormValues } from 'modules/profiles/profile-preferences'
import { trimmedString } from 'shared/hooks'
import { AnySchema, array, boolean, number, object } from 'yup'

export { Preferences } from './Preferences'

export const schema = object().shape({
  availability: trimmedString().required('Select your currently availability'),
  noticePeriod: number().required('Select your notice period.'),
  jobTypes: object({
    longTerm: boolean().test(
      'at_least_one_option_selected',
      'Please select an option',
      function (value) {
        if (!value && !this.parent.shortTerm) return false
        return true
      },
    ),
    shortTerm: boolean().test(
      'at_least_one_option_selected',
      'Please select an option',
      function (value) {
        if (!value && !this.parent.longTerm) return false
        return true
      },
    ),
  }),
  freelancingJobHours: object().shape({
    min: number(),
    max: number(),
  }),
  companySizes: object({
    micro: trimmedString(),
    small: trimmedString(),
    medium: trimmedString(),
    large: trimmedString(),
    very_large: trimmedString(),
    huge: trimmedString(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as Record<CompanySize, any>),
  notWillingToWorkIn: array(
    object({
      id: number(),
      label: trimmedString(),
    }),
  ),
  pausedUntil: trimmedString(),
  allowsAdvertiseProfile: boolean(),
} as Record<keyof PreferencesFormValues, AnySchema>)

export const defaultValues = {
  availability: 'active',
  noticePeriod: 0,
  jobTypes: {
    longTerm: false,
    shortTerm: false,
  },
  freelancingJobHours: {
    min: undefined,
    max: undefined,
  },
  companySizes: {
    micro: undefined,
    small: undefined,
    medium: undefined,
    large: undefined,
    very_large: undefined,
    huge: undefined,
  },
  notWillingToWorkIn: [],
  allowsAdvertiseProfile: false,
} as PreferencesFormValues
