import { AnySchema, object } from 'yup'

import { UpdateProfileBioSchema } from 'profile/domain'
import { trimmedString } from 'shared/hooks'

export { Bio } from './Bio'

export const MAX_BIO_TEXT_LENGTH = 1000

export const schema = object().shape<Record<keyof UpdateProfileBioSchema, AnySchema>>({
  bioText: trimmedString().max(MAX_BIO_TEXT_LENGTH).min(1, 'Required field.'),
})
