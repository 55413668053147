import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { CompanyBasicInformationSchema } from 'company/domain'

export const useCompany = () => {
  const { patch } = useNetwork()
  const client = useQueryClient()
  const { toastSuccess, toastError } = useToast()

  const { captureException } = useMonitoring()

  const { mutateAsync: update, isLoading } = useMutation(
    (values: CompanyBasicInformationSchema) => patch('company', values),
    {
      onSuccess: () => {
        toastSuccess('Company updated.')
        client.invalidateQueries('company-info')
      },
      onError: (error) => {
        toastError('An error ocurred when updating the company.')
        captureException(error)
      },
    },
  )

  return { update, isLoading }
}
