import { trimmedString } from 'shared/hooks'
import { boolean, number, object } from 'yup'

export const schema = object().shape({
  compensation: number().nullable(),
  requestProfileImprovements: boolean(),
  improvements: trimmedString().when('requestProfileImprovements', {
    is: true,
    then: trimmedString()
      .required('Inform the improvements.')
      .test('empty_content', 'Inform the improvements.', function (value) {
        return Boolean(value?.replace('<p></p>\n', ''))
      }),
    otherwise: trimmedString().nullable(),
  }),
})
