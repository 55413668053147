import classNames from 'classnames'
import { Autocomplete, Suggestion, FieldWrapper, Label } from 'shared/components/atoms'
import { useForm, trimmedString } from 'shared/hooks'
import { useEffect, useMemo } from 'react'
import { number, object } from 'yup'

let defaultClient = { id: 0, label: '' } as Suggestion

const schema = object().shape({
  client: trimmedString(),
  clientId: number().required(),
  jobListing: trimmedString(),
  jobListingId: number().required(),
})

interface JobListingSelectorProps {
  clients: Array<Suggestion>
  jobListings: Array<{ id: string; label: string }>
  onClientIdChange: (id: number) => void
  onJobListingIdChange: (string: string) => void
  className?: string
}

export const JobListingSelector = ({
  clients,
  jobListings,
  onClientIdChange,
  onJobListingIdChange,
  className,
}: JobListingSelectorProps) => {
  const defaultValues = { clientId: 0, jobListingId: '' }

  const { register, setValue, watch } = useForm({ defaultValues, schema })
  const clientId = watch('clientId') as number
  const jobListingId = watch('jobListingId') as string

  useEffect(() => {
    onClientIdChange(clientId)
    onJobListingIdChange(jobListingId)
  }, [clientId, jobListingId])

  useEffect(() => {
    handleClientSelected(defaultClient)
  }, [])

  const handleClientSelected = async (item: Suggestion) => {
    defaultClient = item
    setValue('clientId', item.id)
    setValue('client', item.label)

    if (item.id !== clientId) {
      setValue('jobListingId', '')
      setValue('jobListing', '')
    }
  }

  const handleJobListingSelected = async (item: Suggestion) => {
    setValue('jobListingId', item.id)
    setValue('jobListing', item.label)
  }

  const actionDisabled = useMemo(() => !clientId || !jobListings.length, [clientId, jobListings])
  const noListingAvailable = useMemo(() => !jobListings.length && clientId, [clientId, jobListings])

  return (
    <>
      <form className={classNames('flex gap-6', className)}>
        <FieldWrapper className="w-full">
          <Label htmlFor="client" size="text-sm">
            Client
          </Label>
          <Autocomplete
            name="client"
            register={register}
            setValue={setValue}
            suggestions={clients}
            clearInputValue={false}
            allowCreation={false}
            handleAdd={handleClientSelected}
            placeholder="Select"
            autoFocus
          />
        </FieldWrapper>
        <FieldWrapper className="w-full">
          <Label htmlFor="jobListing" size="text-sm">
            Job listing
          </Label>
          <Autocomplete
            name="jobListing"
            register={register}
            setValue={setValue}
            suggestions={jobListings}
            clearInputValue={false}
            allowCreation={false}
            handleAdd={handleJobListingSelected}
            placeholder={noListingAvailable ? 'No listing available' : 'Select'}
            disabled={actionDisabled}
          />
        </FieldWrapper>
      </form>
    </>
  )
}
