import { HiringMode } from 'shared/hooks'
import { CompanySize } from '../../company/enums'
import { Employee } from './employee.domain'
import { Invite } from './invite.domain'
import { Teammate } from './teammate.domain'
import { CompanyBasicInformationSchema } from './company-basic-information-schema.domain'

export class Company {
  id: number
  name: string
  website: Nullable<string>
  linkedInUrl: string
  employees: Array<Employee>
  approved: boolean
  invites: Array<Invite>
  marketType: Nullable<{
    id: number
    label: string
  }>
  companySize: Nullable<CompanySize>
  hiringMode: HiringMode

  get teammates(): Array<Teammate> {
    return this.employees
      .map(({ email, role }) => ({ email, role, accepted: true }))
      .concat(this.invites.map(({ email, role }) => ({ email, role, accepted: false })))
  }

  get normalizedWebsite() {
    return this.website?.replace('http://', '').replace('https://', '').replace('www.', '')
  }

  get basicInformationValues(): CompanyBasicInformationSchema {
    return {
      name: this.name,
      website: this.website,
      linkedInUrl: this.linkedInUrl,
      marketTypeId: this.marketType?.id ?? null,
      companySize: this.companySize,
    }
  }
}

export class CompanyBasicInfo {
  id: number
  name: string
  website: string
  approved: boolean
  activeListingsCount: number
  usersCount: number
}
